<template>
  <div>
    <Header :title="$t('global.boards')" />
    <div v-if="!loading" class="h-ful border-b p-5">
      <div class="px-4 py-5 bg-white">
        <draggable
            :list="boards"
            :group="{ name: 'row' }"
            ghost-class="draggable"
            v-bind="dragOptions"
            @change="handleChange"
        >
          <transition-group type="transition" name="flip-list"
                            class="row wrap justify-space-around mt-2 grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
            <div v-for="board in boards" :key="board.id" class="row-v">
              <Card :board="board" />
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import Card from '@/components/PublicPages/Card/Card';
import Header from '../../../components/PublicPages/Header';

export default {
  name: 'PublicBoards',
  components: {
    Header,
    Card,
    draggable
  },
  data() {
    return {
      loading: true,
      boards: []
    }
  },
  computed: {
    ...mapGetters(['getPublicBoards']),
    dragOptions() {
      return {
        animation: 300,
        group: 'description',
        disabled: false
      };
    }
  },
  watch: {
    getPublicBoards(newValue) {
      this.boards = newValue;
      this.loading = false;
    }
  },
  created() {
    this.handleGetPublicBoards({ uuid: this.$route.params.uuid });
  },
  methods: {
    ...mapActions({
      handleGetPublicBoards: 'fetchPublicBoards'
    }),
    handleChange(element) {

    }
  }
}
</script>

<style scoped>

</style>
