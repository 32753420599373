<template>
  <div class="bg-white shadow-md rounded-lg">
    <div class="p-5 cursor-pointer">
      <router-link :to="{ name: 'public-listings', params: { uuid: $route.params.uuid, boardUuid: board.uuid } }">
        <div class="flex items-center">
          <div class="w-0 flex-1">
            <div class="mt-1 text-sm text-gray-900">
              {{ board.title }}
            </div>
          </div>
        </div>
        <div class="mt-3 pr-6 h-70p"></div>
      </router-link>
    </div>
    <div class="bg-gray-50 px-5 py-3 flex">
      <CardIcon />
      <div class="text-sm w-full flex justify-between">
        <a href="#" class="text-sm font-small text-gray-500 cursor-default">
          {{ board.tasksCount }}
        </a>
        <a href="#" class="text-sm font-small text-gray-500 ml-3 cursor-default">
          Updated on {{ board.updatedAt | parseBoardDate }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CardIcon from '@/components/Icons/CardIcon';
export default {
  name: 'Card',
  components: { CardIcon },
  props: {
    board: {
      type: Object,
      'default': Object
    }
  }
}
</script>

<style scoped>

</style>
